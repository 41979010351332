import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import moment from "moment"
import clsx from "clsx"
// import { useInView } from "react-intersection-observer"
import Slider from "react-slick"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import { SiteLayout, Head, Marker, AizulabMap } from "~/components"
import BackgroundImage from "gatsby-background-image"
import { IconTag, IconArrowRight } from "~/icons"
import * as styles from "./index.module.scss"

const slickSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
}

const HomePage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const entries = data.entries.nodes
  const news = data.news.nodes
  const [aizu, setAizu] = useState(false)
  const [aict, setAict] = useState(false)

  function onChildMouseEnter(enterKey) {
    if (enterKey === 0) {
      setAizu(true)
    } else if (enterKey === 1) {
      setAict(true)
    }
  }

  function onChildMouseLeave(leaveKey) {
    if (leaveKey === 0) {
      setAizu(false)
    } else if (leaveKey === 1) {
      setAict(false)
    }
  }

  // const { ref, inView } = useInView({
  //   threshold: 0,
  // })

  return (
    <SiteLayout title={siteTitle} isHomePages>
      <Head
        title="to Advance Knowledge for Humanity"
        description="会津ラボはiOS/Androidアプリケーションなど、スマートフォン向けのアプリケーションソフトウェアの開発を主に行っている会津大学発ベンチャー企業です。ホームページの制作や各種デザインも承ります。"
        isHomePage
      />

      {/* <div className={clsx(styles.popup, inView && styles.observe)}>
 <StaticImage
 src="../../content/assets/hasura.png"
 width={100}
 height={140}
 alt=""
 quality={80} />
 <div className={styles.popupBox}>
 <p className={styles.popupText}>
 2022年6月に、会津ラボはHASURAwardsの"Open Source Hero"を受賞しました。
 これはHasura CE Edition（オープンソース）を使用して素晴らしいプロジェクトを作成したチームに贈るものです
 </p>
 <a className={styles.popupLink} href="https://www.aizulab.com/blog/hasuracon22/">詳細はこちら</a>
 </div>
 </div> */}

      <BackgroundImage
        fluid={data.desktop.childImageSharp.fluid}
        className={styles.bgImage}
      />

      <div className={styles.hero}>
        <p className={styles.heroTitle}>
          <span>あたらしい時代</span>
          <br />
          <span>いまこそDXの実現を</span>
        </p>
        <div className={styles.heroMessage}>
          <div className={styles.heroText}>
            私たちは福島県の会津若松市にある小さなシステム開発会社です。
            <br />
            ご覧の通りコロナ禍の今はほぼフルリモート対応、
            <br />
            あたらしい時代のカタチに沿って変化し続けます。
            <br />
            今こそITの力でイノベーションを。
            <br />
            会津ラボは <strong>*スマートシティ会津若松</strong>{" "}
            に参画しています。
          </div>
        </div>
      </div>

      <section>
        <figure className={styles.figure}>
          <div className={styles.figWrapper}>
            <div className={styles.figInner}>
              <div className={styles.figCol}>
                <figcaption className={styles.figCaption}>
                  コンパクトな
                  <br />
                  企業メリットを
                  <br />
                  最大限に活かす
                </figcaption>
                <p className={styles.figText}>
                  少人数の自己組織化チームで全てのプロセスを満たします。
                  <br />
                  企画・営業、システム設計、UI設計、デザイン、
                  <br />
                  フロントエンド、バックエンド、
                  <br />
                  Web開発、iOS開発、Android開発、AWS構築、
                  <br />
                  ハード設計、IoT開発、
                  <br />
                  幅広く、一貫したシステム開発が可能です。
                </p>
              </div>
              <div className={styles.figImg} style={{ maxWidth: "440px" }}>
                <StaticImage
                  src="../images/index/img_merit.png"
                  alt=""
                  quality={80}
                />
              </div>
            </div>
          </div>
        </figure>
        <figure className={styles.figure}>
          <div className={styles.figWrapper_reverse}>
            <div className={styles.figInner_reverse}>
              <div className={styles.figCol_reverse}>
                <figcaption className={styles.figCaption_reverse}>
                  問題を解決できる
                  <br />
                  サービスを届ける
                </figcaption>
                <p className={styles.figText_reverse}>
                  プロセスやツールよりも個人と対話を、
                  <br />
                  包括的なドキュメントよりも動くソフトウェアを、
                  <br />
                  契約交渉よりも顧客との協調を、
                  <br />
                  計画に従うことよりも変化への対応を、
                  <br />
                  全ては顧客の問題を解決するために
                  <br />
                  アジャイルマニフェストを実践しています。
                </p>
              </div>
              <div className={styles.figImg} style={{ maxWidth: "511px" }}>
                <StaticImage
                  src="../images/index/img_service.png"
                  alt=""
                  quality={80}
                />
              </div>
            </div>
          </div>
        </figure>
      </section>

      <section className={styles.sectionService}>
        <h2 className={styles.serviceHeading}>サービス</h2>
        <Slider {...slickSettings}>
          <div className={styles.serviceItem}>
            <div className={styles.panel}>
              <div className={styles.panelColGhost}>&nbsp;</div>
              <StaticImage
                src="../images/index/aizukko_mockup.png"
                alt=""
                height={700}
                quality={100}
                className={styles.panelImg}
              />
              <div className={styles.panelCol}>
                <h3 className={styles.panelTitle}>あいづっこ＋（プラス）</h3>
                <div className={styles.panelTag}>iOS/Android</div>
                <p className={styles.panelText}>
                  会津若松市教育ポータルサイト
                  <br />
                  「あいづっこweb」のスマートフォンアプリ。
                  <br />
                  子どもたちの学校生活の紹介や、学校だより等のPDF、行事のお知らせ、豪雨災害などの緊急情報を地図上で確認できます。
                </p>
                <Link
                  to="/cases/aizukko_plus/"
                  className={styles.panelMoreLink}
                >
                  <span>more</span>
                  <IconArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </Slider>
      </section>

      <section className={styles.sectionNews}>
        <div className={styles.sectionNewsInner}>
          <article className={styles.media}>
            <h2 className={styles.mediaTitle}>NEWS</h2>
            {news.map(newsData => (
              <div key={`news_${newsData.date}`} className={styles.mediaBody}>
                <p className={styles.mediaText}>{newsData.title}</p>
                {newsData.content && parse(newsData.content)}
                <time className={styles.newsDate}>
                  {moment(newsData.date).format("YYYY-MM-DD")}
                </time>
              </div>
            ))}
          </article>
          <Link to="/news/" className={styles.newsMoreLink}>
            <span>more</span>
            <IconArrowRight />
          </Link>
        </div>
      </section>

      <section className={styles.sectionBlog}>
        <h2 className={styles.sectionBlogHeading}>開発者ブログ</h2>
        <p className={styles.sectionBlogText}>
          日々の中で発見したこと、
          <br />
          取り組んでいること、
          <br />
          ブログを見れば中の人がわかります。
        </p>
        <div className={styles.blogRow}>
          {entries.map(entry => (
            <Link
              key={entry.id}
              to={`/blog${entry.uri}`}
              className={styles.blogCard}
            >
              {entry.featuredImage?.node?.localFile?.childImageSharp ? (
                <GatsbyImage
                  image={getImage(
                    entry.featuredImage?.node?.localFile?.childImageSharp
                  )}
                  alt=""
                  quality={80}
                  height={225}
                  imgStyle={{
                    transform: "none",
                    transition: "transform 225ms ease",
                    willChange: "transform",
                  }}
                />
              ) : (
                <StaticImage
                  src="../images/common/noimg.png"
                  alt=""
                  quality={80}
                  imgStyle={{
                    transform: "none",
                    transition: "transform 225ms ease",
                    willChange: "transform",
                  }}
                />
              )}
              <div className={styles.blogCol}>
                <p className={styles.blogTitle}>{entry.title}</p>
                <div className={styles.blogTag}>
                  <IconTag />
                  {entry.categories.nodes[0].name}
                </div>
              </div>
            </Link>
          ))}
        </div>
        <Link to="/blog/" className={styles.sectionBlogBtn}>
          <span>more</span>
          <IconArrowRight />
        </Link>
      </section>

      <section className={styles.sectionAddress}>
        <div className={styles.addressWrapper}>
          <div className={styles.addressMap}>
            <AizulabMap
              onChildMouseEnter={enterData => onChildMouseEnter(enterData)}
              onChildMouseLeave={leaveData => onChildMouseLeave(leaveData)}
            >
              <Marker
                aizu={aizu}
                alt="本社の場所"
                lat={37.522718}
                lng={139.913635}
              />
              <Marker
                aict={aict}
                alt="AiCTオフィスの場所"
                lat={37.492359}
                lng={139.930606}
              />
            </AizulabMap>
          </div>
          <div className={styles.addressCol}>
            <h2 className={styles.addressTitle}>株式会社会津ラボ</h2>
            <address className={styles.addressItem}>
              <p className={styles.addressText}>
                本社
                <br />
                〒965-0059
                <br className={styles.addressTextBreak} />{" "}
                福島県会津若松市インター西５３番地
                <br />
                TEL 0242-23-8285
                <br className={styles.addressTextBreak} /> FAX 0242-23-8286
              </p>
              <StaticImage
                src="../images/index/pic_aizulab.jpg"
                alt=""
                width={105}
                height={70}
                quality={100}
              />
            </address>
            <address className={styles.addressItem}>
              <p className={styles.addressText}>
                AiCTオフィス
                <br />
                〒965-0872
                <br className={styles.addressTextBreak} />{" "}
                福島県会津若松市東栄町１−７７
                <br />
                スマートシティＡｉＣＴ内
              </p>
              <StaticImage
                src="../images/index/pic_aict.jpg"
                alt=""
                width={105}
                height={70}
                quality={100}
              />
            </address>
          </div>
        </div>
      </section>
    </SiteLayout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    desktop: file(relativePath: { eq: "hero_office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1380, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    news: allWpNews(sort: { fields: [date], order: DESC }, limit: 1) {
      nodes {
        title
        date
        content
      }
    }
    entries: allWpPost(sort: { fields: [date], order: DESC }, limit: 3) {
      nodes {
        id
        uri
        title
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  height: 225
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
